import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Counter() {
  const [count, setCount] = useState(0);
  const { t, i18n } = useTranslation();
  return (
    <div>
      <h1>Counter</h1>

      <p>This is a simple example of a React component.</p>

      <p aria-live="polite">Current count: <strong>{count}</strong></p>

      <button className="btn btn-primary" onClick={() => setCount(count + 1)}>{t('Increment')}</button>
    </div>
  );
}
