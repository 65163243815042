import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamCreate } from "./TeamCreate"
import { AuthenticatedTemplate } from "@azure/msal-react";


export function Teams() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const fetchData = async () => {
    const headers = new Headers();
    

    const options = {
      method: "GET",
      headers: headers
    };
    const response = await fetch('teams', options);
    const data = await response.json();
    return data;
  };

  const loadTeams = async () => {
    fetchData().then(response => {
      setTeams(response);
      setLoading(false);
    });}

  useEffect(() => { 
    loadTeams();
    //});
  }, []);

  let contents = loading
    ? <p><em>Loading...</em></p>
    : renderTeamsTable(teams);

  return (
    <div>
      <h1 id="tabelLabel" >Teams</h1>
      <br />
      <AuthenticatedTemplate>
        <TeamCreate onSubmit={loadTeams}/>
        </AuthenticatedTemplate>
        <br/>
      <h6>Registered teams:</h6>
      {contents}
    </div>
  );

  function renderTeamsTable(teams) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Location')}</th>
          </tr>
        </thead>
        <tbody>
          {teams.map(forecast =>
            <tr key={forecast.id}>
              <td>{forecast.name}</td>
              <td>{forecast.location}</td>
            </tr>
          )}
        </tbody>
        </table>
    );
  }
}



