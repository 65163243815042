import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../authConfig";

export function Login() {
  const { instance, accounts } = useMsal();

  useEffect(async () => {
    await getAccessToken(instance, accounts);
  }, []);

  return (<div></div>);

}