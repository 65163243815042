import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { SeasonCreate } from "./SeasonCreate"
import { AuthenticatedTemplate } from "@azure/msal-react";
import { SeasonCreate } from "./Season/SeasonCreate"
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';




export function Competition() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState([]);
  const { competitionId } = useParams();

  const fetchData = async () => {
    const headers = new Headers();


    const options = {
      method: "GET",
      headers: headers
    };
    const response = await fetch('competitions/' + competitionId, options);
    const data = await response.json();
    return data;
  };

  const loadCompetition = async () => {
    fetchData().then(response => {
      setCompetition(response);
      setLoading(false);
    });
  }

  useEffect(() => {    
    loadCompetition();
    //});
  }, []);

  let contents = loading
    ? <p><em>Loading...</em></p>
    : renderSeasons(competition.seasons);

  return (    
    <div>
      <h1 id="tabelLabel" >{competition.name}</h1>
      <br />
      <AuthenticatedTemplate>
          <SeasonCreate onSubmit={loadCompetition} competitionId={competitionId}/>
      </AuthenticatedTemplate>
      <br />
      {contents}
      </div>
  );

  function renderSeasons(seasons) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>{t('Saisons')}</th>
          </tr>
        </thead>
        <tbody>
          {competition.seasons.map(season =>
            <tr key={season.id}>
              <td><Link to={`/competitiondetail/${competition.id}/season/${season.id}`}>{season.name}</Link></td>
            </tr>
          )}
        </tbody>
        </table>
    );
  }
}



