import { msalConfig } from "../authConfig";

import { PublicClientApplication } from "@azure/msal-browser";

// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(msalConfig);

const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
    }

    const loginRequest = {
        scopes: ["https://teamcomps.onmicrosoft.com/CompsAPI/Competition.Write", "https://teamcomps.onmicrosoft.com/CompsAPI/Competition.Read"]
    };

    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken
};


export async function createSeason(competitionId, seasonName, seasonType) {

    let accessToken = await acquireAccessToken();

    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            name: seasonName,
            seasonType: seasonType,
        }),
    };
    const response = await fetch('competitions/' + competitionId + '/seasons', options);
    return response.status;
}

export async function getSeason(competitionId, seasonId) {
    const headers = new Headers();

    const options = {
        method: "GET",
        headers: headers
    };
    const response = await fetch('competitions/' + competitionId + '/seasons/' + seasonId, options);
    const data = await response.json();
    return data;
};

export async function getSeasonRounds(competitionId, seasonId) {
    const headers = new Headers();


    const options = {
        method: "GET",
        headers: headers
    };
    const response = await fetch('competitions/' + competitionId + '/seasons/' + seasonId + '/rounds', options);
    const data = await response.json();

    return data;
};

export async function getCompetitions() {
    const headers = new Headers();

    const options = {
        method: "GET",
        headers: headers
    };
    const response = await fetch('competitions', options);
    const data = await response.json();
    return data;
}

export async function getCompetition(competitionId) {
    const headers = new Headers();

    const options = {
        method: "GET",
        headers: headers
    };
    const response = await fetch('competitions/' + competitionId, options);
    const data = await response.json();
    return data;
}

