import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { storeCurrentPath } from './LocalRedirectUrlStorage';


function handleLogin(instance) {
  storeCurrentPath();
  instance.loginRedirect(loginRequest).catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const { t, i18n } = useTranslation();

  return (
    <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>{t('Login')}</Button>
  );
}