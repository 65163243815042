import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from "@azure/msal-react";
import { createSeason } from "../../helpers/apiAccess";
import { Form } from "react-bootstrap";
import { Button, Alert } from "react-bootstrap";


export function SeasonCreate(props) {
    const { t, i18n } = useTranslation();
    const { instance, accounts } = useMsal();
    const [name, setName] = useState("");
    const [location, setLocation] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState("");
    const [seasonType, setSeasonType] = useState("League");
    const formRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await createSeason(props.competitionId, name, seasonType);
        if (response === 200) {
            formRef.current.reset();
            setError("");
            setName("");
            setLocation("");
            props.onSubmit();
            setInProgress(false)
        }
        else if (response < 500) {

            setError("Season already exists or invalid name field")
        }
        else {
            setError("Unexpected error occured")
        }
    }

    return (
        <div>
            {!inProgress ?
                <Button variant="primary" type="submit" onClick={() => setInProgress(true)}>
                    {t('Add Season')}
                </Button>
                :
                <div className="border rounded px-3 py-2">
                    <h6>{t('Add new Season:')}</h6>
                    <Form onSubmit={handleSubmit} ref={formRef}>
                        <Alert variant="danger" show={error !== ""}>
                            {error}
                        </Alert>
                        <Form.Group className="mb-3" controlId="formGroupSeasonName">
                            <Form.Label>{t('Name')}</Form.Label>
                            <Form.Control type="text" placeholder={t('Enter name of the season')} onChange={(e) => setName(e.target.value)} />
                            <Form.Label>{t('Typ')}</Form.Label>
                            <Form.Control as="select" onChange={(e) => setSeasonType(e.target.value)} value="League">
                                <option key="League">League</option>
                                <option key="Knockout">Knockout</option>
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {t('Submit')}
                        </Button>
                        {' '}
                        <Button onClick={() => setInProgress(false)} className="ml-1">
                            {t('Cancel')}
                        </Button >
                    </Form>
                </div>
            }
        </div>
    );
}




