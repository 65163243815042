import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { SeasonCreate } from "./SeasonCreate"
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { SeasonTeamCreate } from "./SeasonTeamCreate"
import { RoundCreate } from "../Round/RoundCreate"
import { Round } from "../Round/Round"
import { SeasonRanking } from "./SeasonRanking"
import { Accordion} from "react-bootstrap";
import { getSeason, getSeasonRounds, getCompetition } from "../../helpers/apiAccess";
import { compareRounds, getLatestRoundIndex, getRoundName } from "../../helpers/utils"


export function Season() {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [season, setSeason] = useState();
    const [competition, setCompetition] = useState();
    const { competitionId, seasonId } = useParams();
    const [roundsLoaded, setRoundsLoaded] = useState(false);
    const [rounds, setRounds] = useState([]);

    const loadSeason = async () => {
        getSeason(competitionId, seasonId).then(response => {
            setSeason(response);
            setLoading(false);
        });
        getSeasonRounds(competitionId, seasonId).then(response => {
            setRounds(response);
            setRoundsLoaded(true);
        });
        getCompetition(competitionId).then(response => {
            setCompetition(response);
        });
    }

    useEffect(() => {
        loadSeason();
        //});
    }, []);

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <div>

            <h1 id="tabelLabel" >{(competition ? competition.name : " ") + " " + (season ? season.name : " ")}</h1>
            <br />
            <AuthenticatedTemplate>
                <SeasonTeamCreate onSubmit={loadSeason} competitionId={competitionId} seasonId={seasonId} />
            </AuthenticatedTemplate>
            <br />
            <SeasonRanking rankingObj={season?.ranking} seasonType={season?.seasonType} />
            <AuthenticatedTemplate>
                <RoundCreate onSubmit={loadSeason} season={season} competitionId={competitionId} seasonId={seasonId} />
                <br />
                <br />
            </AuthenticatedTemplate>

            <div>
                <br />
                <h5>{t('Spielrunden')}</h5>
                <br />
                <Accordion defaultActiveKey={[5]} alwaysOpen>
                    {rounds?.sort((a, b) => compareRounds(a, b)).map((md, index) => (
                        <Accordion.Item eventKey={index} key={md.id}>
                            <Accordion.Header>
                                {getRoundName(md, season, rounds) + " - " + new Date(md.date).toLocaleDateString('de-DE', dateOptions)}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Round round={md} onSubmit={loadSeason} competitionId={competitionId} season={season} seasonId={seasonId} />
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div >
        </div >
    );
}



