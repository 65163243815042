import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CompetitionCreate } from "./CompetitionCreate"
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Link } from 'react-router-dom';
import { getCompetitions } from "../helpers/apiAccess";

export function Competitions() {
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();



  const loadCompetitions = async () => {
    getCompetitions().then(response => {
      setCompetitions(response);
      setLoading(false);
    });}

  useEffect(() => { 
    loadCompetitions();
    //});
  }, []);

  let contents = loading
    ? <p><em>Loading...</em></p>
    : renderCompetitionsTable(competitions);

  return (
    <div>
      <h1 id="tabelLabel" >Wettbewerbe</h1>
      <br />
      <AuthenticatedTemplate>
        <CompetitionCreate onSubmit={loadCompetitions}/>
        </AuthenticatedTemplate>
        <br/>
      <h6>Aktuelle Wettbewerbe:</h6>
      {contents}
    </div>
  );

  function renderCompetitionsTable(competitions) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Location')}</th>
          </tr>
        </thead>
        <tbody>
          {competitions.map(competition =>
            <tr key={competition.id}>
              <td><Link to={`/competitiondetail/${competition.id}`}>{competition.name}</Link></td>
              <td>Kreis Harz</td>
            </tr>
          )}
        </tbody>
        </table>
    );
  }
}



