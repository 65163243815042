import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../authConfig";
import { Form } from "react-bootstrap";
import { Button, Alert } from "react-bootstrap";


export function TeamCreate(props) {
  const { t, i18n } = useTranslation();
  const { instance, accounts } = useMsal();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const formRef = useRef(null);

  const handleSubmit = async (e) => {

    e.preventDefault();

    let accessToken = await getAccessToken(instance, accounts)

    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: name,
        location: location
      }),
    };
    const response = await fetch('teams', options);
    if (response.status === 200) {
      formRef.current.reset();
      setError("");
      setName("");
      setLocation("");
      props.onSubmit();
      setInProgress(false)
    }
    else if (response.status < 500) {

      setError("Team already exists or invalid name/location field")
    }
    else {
      setError("Unexpected error occured")
    }
  }


  return (
    <div>
      {!inProgress ?
        <Button variant="primary" type="submit" onClick={() => setInProgress(true)}>
          {t('Add Team')}
        </Button>
        :
        <div className= "border rounded px-3 py-2">
          <h6>{t('Add new Team:')}</h6>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Alert variant="danger" show={error !== ""}>
              {error}
            </Alert>
            <Form.Group className="mb-3" controlId="formGroupTeamName">
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control type="text" placeholder={t('Enter name of the team')} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupTeamCity">
              <Form.Label>{t('Location')}</Form.Label>
              <Form.Control type="text" placeholder={t('Enter location of the team')} onChange={(e) => setLocation(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">
              {t('Submit')}
            </Button>
            {' '}
            <Button onClick={() => setInProgress(false)} className="ml-1">
              {t('Cancel')}
            </Button >
          </Form>
        </div>
      }
    </div>
  );
}




