import React, { useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../authConfig";
import { Form } from "react-bootstrap";
import { Button, Alert } from "react-bootstrap";
import Select from 'react-select'


export function SeasonTeamCreate(props) {
  const { t, i18n } = useTranslation();
  const { instance, accounts } = useMsal();
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const formRef = useRef(null);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teams, setTeams] = useState([]);


  const fetchData = async () => {
    const headers = new Headers();

    const options = {
      method: "GET",
      headers: headers
    };
    const response = await fetch('teams', options);
    const data = await response.json();
    return data;
  };

  const loadTeams = async () => {
    fetchData().then(response => {
      setTeams(response);
    });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    let accessToken = await getAccessToken(instance, accounts)

    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: selectedTeam.id,
      }),
    };
    const response = await fetch('competitions/' + props.competitionId + '/seasons/' + props.seasonId + '/teams', options);
    if (response.status === 200) {
      formRef.current.reset();
      setError("");
      props.onSubmit();
    }
    else if (response.status < 500) {

      setError("Competition already exists or invalid name/location field")
    }
    else {
      setError("Unexpected error occured")
    }
  }

  useEffect(() => {
    loadTeams();
    //});
  }, []);

  return (
    <div>
      {!inProgress ?
        <Button variant="primary" type="submit" onClick={() => setInProgress(true)}>
          {t('Add Team')}
        </Button>
        :
        <div className= "border rounded px-3 py-2">
          <h6>{t('Add/Remove Team:')}</h6>

          <Form onSubmit={handleSubmit} ref={formRef}>
            <Alert variant="danger" show={error !== ""}>
              {error}
            </Alert>
            <Form.Group className="mb-3" controlId="formGroupSeasonName">            
              <Select options={teams == null ? [] : teams.map((team) => ({ "label": team.name, "value": team }))}
                  onChange={option => setSelectedTeam(option.value)}              />
            </Form.Group>            
            <Button variant="primary" type="submit">
              {t('Add')}
            </Button>
            {' '}
            <Button onClick={() => setInProgress(false)} className="ml-1">
              {t('Close')}
            </Button >
          </Form>
        </div>
      }
    </div>
  );
}




