import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_de from "./locales/de/common.json";
import common_en from "./locales/en/common.json";

i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)

  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: common_en               // 'common' is our custom namespace
      },
      de: {
        translation: common_de
      },
    },
    lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "de",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;