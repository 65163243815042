import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { MatchesTable } from "../Round/MatchesTable";
import { compareRounds, getRoundName } from "../../helpers/utils"


export function LatestMatches() {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [nextRounds, setNextRounds] = useState([]);
    const [lastRounds, setLastRounds] = useState([]);
    const { competitionId, seasonId } = useParams();

    const loadRounds = async () => {
        const headers = new Headers();


        const options = {
            method: "GET",
            headers: headers
        };
        var response = await fetch('lastrounds?pageNumber=1&pageSize=5', options);
        var data = await response.json();
        setLastRounds(data);
        response = await fetch('nextrounds?pageNumber=1&pageSize=5', options);
        data = await response.json();
        setNextRounds(data);
        setLoading(false);
    };

    useEffect(() => {
        loadRounds();
        //});
    }, []);

    function getScore(match) {
        var setsA = 0;
        var setsB = 0;
        for (var sr of match.result.setResults) {
            if (sr.teamAPoints != 0 && sr.teamBPoints != 0) {
                if (sr.teamAPoints > sr.teamBPoints) setsA++
                else setsB++;
            }
        }
        return setsA + "-" + setsB;
    }
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


    return (
        loading ? <p><em>Loading...</em></p>
            :
            <div>
                { nextRounds.length > 0 &&
                    <div>
                <h5>Nächste Spieltage:</h5>
                <br />
                {nextRounds.filter(md => new Date(md.date) > Date.now()).sort((a, b) => compareRounds(a, b)).reverse().map(md => (
                    <div key={md.id}>
                        <h6>
                            <Link to={`/competitiondetail/${md.competitionId}/season/${md.seasonId}`}>
                                {md.competitionName + ", " + new Date(md.date).toLocaleDateString('de-DE', dateOptions)} &raquo;
                            </Link>
                        </h6>
                        <h6 id="tabelLabel" >{md?.name ?? ""}</h6>
                        <MatchesTable matches={md.matches} />
                        <br />
                    </div>
                ))}
                        </div>
                }
                <h5>Letzte Spieltage:</h5>
                <br />
                {lastRounds.filter(md => new Date(md.date) <= Date.now()).sort((a, b) => compareRounds(a, b)).map(md => (
                    <div key={md.id}>
                        <h6>
                            <Link to={`/competitiondetail/${md.competitionId}/season/${md.seasonId}`}>
                                {md.competitionName + ", " + new Date(md.date).toLocaleDateString('de-DE', dateOptions)} &raquo;
                            </Link>
                        </h6>
                        <h6 id="tabelLabel" >{md?.name ?? ""}</h6>
                        <MatchesTable matches={md.matches} showResults="true"/>
                        <br />
                    </div>
                ))}
            </div>
    );


}



