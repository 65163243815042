import React, { useState } from 'react';
import { Collapse, Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

export function NavMenu() {

    const [collapsed, setCollapsed] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    return (
        <header>
            <Navbar collapseOnSelect expand="sm">
                <Container>
                    <Navbar.Brand as={Link} to="/">HarzVolley</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <Nav.Item>
                                <Nav.Link as={Link} className="text-dark" to="/">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} className="text-dark" to="/competition">Wettbewerbe</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} className="text-dark" to="/team">Teams</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                    </Navbar.Collapse>
                </Container>

            </Navbar>

        </header>
    );
}

