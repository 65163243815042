import React from 'react';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { LatestMatches } from "./LatestMatches";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";



export function Home() {
    const appInsights = useAppInsightsContext();
    const trackComponent = useTrackMetric(appInsights, "Home");
    return (
        <div onMouseEnter={trackComponent}>
            <h1 >HarzVolley</h1>
            <br/>
            <h3>Volleyball in Harz</h3>
            <br/>
                <br/>           
            <LatestMatches/>
      </div>

    );
  }

