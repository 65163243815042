import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { getAccessToken } from "../../authConfig";
import { Button, Row, Col, Form, Table } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { MatchesTable } from "./MatchesTable";



export function Round(props) {
    const [editing, setEditing] = useState(false);
    const [matches, setMatches] = useState([]);
    const [date, setDate] = useState(new Date());
    const { t, i18n } = useTranslation();
    const { instance, accounts } = useMsal();
    const [error, setError] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const formRef = useRef(null);
    const [roundNumber, setRoundNumber] = useState(0);


    useEffect(() => {
        setMatches([...props.round.matches]);
        setDate(props.round.date);
        setRoundNumber(props.round.number);
        //});
    }, [props.round]);

    const handleSubmit = async (e) => {

        e.preventDefault();

        let accessToken = await getAccessToken(instance, accounts)

        const headers = new Headers();
        headers.append("Content-Type", "application/json")
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                date: date,
                number: roundNumber,
                matches: matches,
            }),
        };
        const response = await fetch('competitions/' + props.competitionId + '/seasons/' + props.seasonId + '/rounds/' + props.round.id, options);
        if (response.status === 200) {
            formRef.current.reset();
            setError("");
            setEditing(false);
            props.onSubmit();
        }
        else if (response.status < 500) {

            setError("Competition already exists or invalid name/location field")
        }
        else {
            setError("Unexpected error occured")
        }
    }


    const handleDelete = async (e) => {

        e.preventDefault();

        let accessToken = await getAccessToken(instance, accounts)

        const headers = new Headers();
        headers.append("Content-Type", "application/json")
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "DELETE",
            headers: headers,
        };
        const response = await fetch('competitions/' + props.competitionId + '/seasons/' + props.seasonId + '/rounds/' + props.round.id, options);
        if (response.status === 200) {
            setError("");
            setInProgress(false);
            props.onSubmit();
        }
        else if (response.status < 500) {
            setError("Could not delete round")
        }
        else {
            setError("Unexpected error occured")
        }
    }

    const teamSelector = (index, team, isTeamA) =>
        <Form.Control as="select" size="sm" onChange={(e) => setTeam(e.target.value, index, isTeamA)} value={team?.name}>
            {props.season?.teams?.map(team =>
                <option key={team?.id}>{team?.name}</option>)}
        </Form.Control>

    const createMatch = () => {
        setMatches([
            ...matches,
            { teamA: props.season?.teams[0], teamB: props.season?.teams[0], result: { setResults: [{ teamAPoints: 0, teamBPoints: 0, setNumber: 1 }, { teamAPoints: 0, teamBPoints: 0, setNumber: 2 }, { teamAPoints: 0, teamBPoints: 0, setNumber: 3 }] } }
        ]);
    };

    const deleteMatch = (index) => {
        var removed = matches.splice(index, 1);
        setMatches([...matches]);
    };

    const setTeam = (name, index, isTeamA) => {
        var team = props.season.teams.find(t => t.name == name)
        if (isTeamA) {
            matches[index].teamA = team
        }
        else {
            matches[index].teamB = team

        }
        setMatches([
            ...matches
        ]);
    };

    const setTeamPoints = (value, index, setIndex, isTeamA) => {
        if (isTeamA) {
            matches[index].result.setResults[setIndex].teamAPoints = value
        }
        else {
            matches[index].result.setResults[setIndex].teamBPoints = value
        }
        setMatches([
            ...matches
        ]);
    };

    function roundEdit() {
        return (
            <div className="border rounded px-3 py-2">
                { }
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <Form.Group as={Row} className="mb-3" controlId="formGroupRoundNumber">
                        <Form.Label column sm={2}>{t('Nummer')}</Form.Label>
                        <Col sm={2}>
                            <Form.Control type="number" placeholder={t('Enter number of the Round')} value={roundNumber} onChange={(e) => setRoundNumber(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="dateInput1">
                        <Form.Label column sm={2}>{t('Spieldatum')}</Form.Label>
                        <Col sm={10}>
                            <Form.Control style={{ width: "33%" }} type="date" value={new Date(date).toISOString().split('T')[0]} placeholder="Date" onChange={(e) => setDate(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <div className="rounded px-3 py-2 table-responsive">
                        <Table striped bordered aria-labelledby="tabelLabel" size="sm">
                            <thead>
                                <tr>
                                    <th>{t('Start Time')}</th>
                                    <th>{t('Location')}</th>
                                    <th>{t('TeamA')}</th>
                                    <th>{t('TeamB')}</th>
                                    <th>{t('Ergebnis')} </th>
                                    <th style={{ width: "2%" }}>
                                        <Button onClick={createMatch} className="ml-1">
                                            {t('+')}
                                        </Button ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {matches.map((match, index) =>
                                    <tr key={index}>
                                        <td >
                                            {                                               
                                                    <div className="row" >
                                                    <div className="col">
                                                        <Form.Control size="sm" type="text" placeholder="Time" />
                                                    </div>
                                                    </div>
                                                }
                                        </td>
                                        <td >
                                            {
                                                <div className="row" >
                                                    <div className="col">
                                                        <Form.Control size="sm" type="text" placeholder="Location" />
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td>{teamSelector(index, match.teamA, true)} </td>
                                        <td>{teamSelector(index, match.teamB, false)}</td>
                                        <td style={{ minWidth: "120px" }}>
                                            {
                                                match.result?.setResults?.sort((a, b) => a.setNumber - b.setNumber).map((rs, setIndex) =>
                                                    <div className="row" key={setIndex}>
                                                        <div className="col"> <Form.Control size="sm" type="text" value={rs.teamAPoints} onChange={(e) => setTeamPoints(e.target.value, index, setIndex, true)} /> </div>
                                                        <div className="col"><Form.Control size="sm" type="text" value={rs.teamBPoints} onChange={(e) => setTeamPoints(e.target.value, index, setIndex, false)} />
                                                        </div>
                                                    </div>
                                                )}
                                        </td>
                                        <td>
                                            <Button onClick={e => deleteMatch(index)} className="ml-1">
                                                {t('-')}
                                            </Button >
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table >
                    </div>

                    <Button variant="primary" type="submit">
                        {t('Spieltag Speichern')}
                    </Button>
                    {' '}
                    <Button onClick={() => { setEditing(false); setMatches([...props.round.matches]); }} className="ml-1">
                        {t('Close')}
                    </Button >
                </Form>
            </div>
        );
    }


    return (
        !editing ?
            <div>
                <AuthenticatedTemplate>
                    <Button onClick={(e) => setEditing(true)} className="ml-1" size="sm">
                        {t('Editieren')}
                    </Button >
                    <Button onClick={(e) => handleDelete(e)} className="ml-1" size="sm">
                        {t('Löschen')}
                    </Button >
                </AuthenticatedTemplate>
                <MatchesTable matches={matches} showResults="true"/>
            </div>
            :
            <div>{roundEdit()}</div>
    );
}



