import React from 'react';
import { Accordion } from 'react-bootstrap';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Footer } from './components/Footer';
import { Teams } from './components/Teams';
import { Clubs } from './components/Clubs';
import { Competitions } from './components/Competitions';
import { Competition } from './components/Competition';
import { Season } from './components/Season/Season';
import { AadCallback } from './components/AadCallback';
import { Login } from './components/Login';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";



export default function App() {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <div>
                <Layout>
                    <Routes>
                        <Route exact path='/' element={<Home/>} />
                        <Route exact path='/aad-callback' element={<AadCallback/>} />
                        <Route exact path='/login' element={<Login/>} />
                        <Route path='/counter' element={<Counter/>} />
                        <Route path='/fetch-data' element={<FetchData/>} />
                        <Route path='/team' element={<Teams/>} />
                        <Route path='/club' element={<Clubs/>} />
                        <Route path='/competition' element={<Competitions/>} />
                        <Route exact path='/competitiondetail/:competitionId' element={<Competition/>} />
                        <Route path='/competitiondetail/:competitionId/season/:seasonId' element={<Season/>} />
                    </Routes>
                </Layout>
            </div>
        </AppInsightsContext.Provider>
    );
}
