import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { getAccessToken } from "../../authConfig";
import { Button, Row, Col, Form, Table } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";


export function MatchesTable(props) {

    const { t, i18n } = useTranslation();


    function getScore(match) {
        var setsA = 0;
        var setsB = 0;
        for (var sr of match.result.setResults) {
            if (sr.teamAPoints != 0 || sr.teamBPoints != 0) {
                if (sr.teamAPoints > sr.teamBPoints) setsA++
                else setsB++;
            }
        }
        return setsA + "-" + setsB;
    }


    return (
        <div className="rounded px-3 py-2 table-responsive">
            <Table striped bordered aria-labelledby="tabelLabel" size="sm">
                <thead>
                    <tr>
                        <th ><span style={{ minWidth: "59px", display: "inline-block" }}>{t('Team A')}</span></th>
                        <th ><span style={{ minWidth: "59px", display: "inline-block" }}>{t('Team B')}</span></th>
                        {
                            props.showResults ? <>
                                <th >{t('Ergebnis')}</th>
                                <th >{t('Sätze')}</th></>
                                :
                                <></>
                        }
                    </tr>
                </thead>
                <tbody>
                    {props.matches.map(match =>
                        <tr key={match.id}>
                            <td xs={3}>{
                                match.winnerId == match.teamA.id ?
                                    <b>{match.teamA.name}</b> :
                                    match.teamA.name
                            }</td>

                            <td xs={3}> <span style={{ minWidth: "45px", display: "inline-block" }}>{

                                match.winnerId == match.teamB.id ?
                                    <b>{match.teamB.name}</b> :
                                    match.teamB.name
                            }</span></td>
                            {
                                props.showResults ? <>
                                    <td xs={2}>{getScore(match)}</td>
                                    <td xs={4} >
                                        {match.result?.setResults?.sort((a, b) => a.setNumber - b.setNumber).map((sr, index) =>

                                            <span key={index}>{sr.teamAPoints}:{sr.teamBPoints}&nbsp;</span>


                                        )}
                                    </td></>
                                    :
                                    <></>

                            }
                        </tr>
                    )}
                </tbody>
            </Table>
        </div >
    );
}



