import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ClubCreate } from "./ClubCreate"
import { AuthenticatedTemplate } from "@azure/msal-react";


export function Clubs() {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const fetchData = async () => {
    const headers = new Headers();
    

    const options = {
      method: "GET",
      headers: headers
    };
    const response = await fetch('clubs', options);
    const data = await response.json();
    return data;
  };

  const loadClubs = async () => {
    fetchData().then(response => {
      setClubs(response);
      setLoading(false);
    });}

  useEffect(() => { 
    loadClubs();
    //});
  }, []);

  let contents = loading
    ? <p><em>Loading...</em></p>
    : renderClubsTable(clubs);

  return (
    <div>
      <h1 id="tabelLabel" >Clubs</h1>
      <br />
      <AuthenticatedTemplate>
        <ClubCreate onSubmit={loadClubs}/>
        </AuthenticatedTemplate>
        <br/>
      <h6>Registered clubs:</h6>
      {contents}
    </div>
  );

  function renderClubsTable(clubs) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Location')}</th>
          </tr>
        </thead>
        <tbody>
          {clubs.map(club =>
            <tr key={club.id}>
              <td>{club.name}</td>
              <td>{club.location}</td>
            </tr>
          )}
        </tbody>
        </table>
    );
  }
}



