import React from 'react';
import { NavMenu } from './NavMenu';
import { Row, Col } from 'react-bootstrap';
//import '../bootstrap-custom.css'


export function Layout(props) {
    return (
        <div>
            <Row>
                <Col md="0" lg="1" xl="2"/>
                <Col md="12" lg="10" xl="8" >
                    <NavMenu />
                    <div className="container">
                        {props.children}
                    </div>
                </Col>
                <Col md="0" lg="1" xl="2"/>
            </Row>
        </div>
    );

}
