import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function handleLogout(instance) {
  instance.logoutRedirect().catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    const { t, i18n } = useTranslation();

  return (
      <Button variant="secondary" className="ml-auto" onClick={() => handleLogout(instance)}>{t('Abmelden')}</Button>
  );
}