import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { getStoredPath, clearStoredPath } from './LocalRedirectUrlStorage';
import { useIsAuthenticated } from "@azure/msal-react";

export function AadCallback() {

  const [localUrl, setLocalUrl] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect( () => {
      let llocalUrl = getStoredPath();
      setLocalUrl(llocalUrl);
      clearStoredPath();
     }, []);

  return (
    isAuthenticated ?
          <Navigate to={localUrl} />
      :
      <div>
        <p>Sorry, something went wrong with login</p>
      </div>
  );
}