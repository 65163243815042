export function compareRounds(md1, md2) {
    var date1 = new Date(md1.date);
    var date2 = new Date(md2.date);

    if (date1 < date2) return 1;
    if (date2 < date1) return -1;

    return md2.number - md1.number;

}

export function getRoundName(round, season, allRounds) {
    if (season?.seasonType == "League") {
        return "Spielrunde " + round.number;
    }
    else if (season?.seasonType == "Knockout") {
        if (round.number == Math.max(...allRounds.map(r => r.number)) && round.matches.length == 1) return "Finale";
        if (round.number == Math.max(...allRounds.map(r => r.number)) - 1 && round.matches.length == 2) return "Halbfinale";
        if (round.number == Math.max(...allRounds.map(r => r.number)) - 2 && round.matches.length == 2) return "Halbfinale";
        if (round.number == Math.max(...allRounds.map(r => r.number)) - 1 && round.matches.length == 1) return "Spiel um Platz drei";
        return "Spielrunde " + round.number;
    }
}

export function getLatestRoundIndex(rounds) {
    if (rounds.length == 0) return 0;

    var date = new Date(rounds[rounds.length - 1].date);

    if (date > new Date()) return rounds.length - 1;

    for (let i = 0; i < rounds.length; i++) {
        date = new Date(rounds[i].date);
        if (date > new Date()) return i - 1;
    }

    return 0;
}