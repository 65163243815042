import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { SeasonCreate } from "./SeasonCreate"
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Table, Row, Col } from "react-bootstrap";



export function SeasonRanking(props) {
    const { t, i18n } = useTranslation();

    return (

        props.rankingObj != null &&
        <div>
            <h5>{t('Tabelle')}</h5>
            <div className="table-responsive">
                <Table striped bordered aria-labelledby="tabelLabel" size="sm">
                    <thead>
                        <tr>
                            <th className="text-center">{t('Platz')}</th>
                            <th >{t('Team')}</th>{
                                props.seasonType === "League" &&
                                <><th className="text-center">{t('Spiele')}</th>
                                    <th className="text-center">{t('Punkte')}</th>
                                    <th className="text-center">{t('Sätze')}</th>
                                    <th className="text-center">{t('Differenz')}</th>
                                    <th className="text-center">{t('Bälle')}</th>
                                    <th className="text-center">{t('Differenz')}</th></>

                            }</tr>
                    </thead>
                    <tbody>
                        {props.rankingObj.positions.map((position, index) =>
                            <tr key={position.teamId}>
                                <td className="text-center">{position.place}.</td>
                                <td>{position.teamName}</td>{props.seasonType === "League" &&
                                    <><td className="text-center">{position.matchesPlayed}</td>
                                        <td className="text-center"><b>{position.points}</b></td>
                                        <td className="text-center">{position.setsWon}:{position.setsLost}</td>
                                        <td className="text-center">{position.setsWon - position.setsLost}</td>
                                        <td className="text-center">{position.pointsWon}:{position.pointsLost}</td>
                                        <td className="text-center">{position.pointsWon - position.pointsLost}</td></>
                                }</tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}



