import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Button, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { getAccessToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";



export function RoundCreate(props) {
    var defaultTeam = props.season?.teams[0];
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());   
    const [roundNumber, setRoundNumber] = useState(0);
    const { t, i18n } = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState("");
    const { instance, accounts } = useMsal();
    const formRef = useRef(null);


    useEffect(() => {
        setMatches([
            { teamA: props.season?.teams[0], teamB: props.season?.teams[0], result: { setResults: [{ teamAPoints: 0, teamBPoints: 0, setNumber: 1 }, { teamAPoints: 0, teamBPoints: 0, setNumber: 2 }, { teamAPoints: 0, teamBPoints: 0, setNumber: 3 }] } }
        ]);
        //});
    }, [props.season]);

    const handleSubmit = async (e) => {

        e.preventDefault();

        let accessToken = await getAccessToken(instance, accounts)

        const headers = new Headers();
        headers.append("Content-Type", "application/json")
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                date: date,               
                number: roundNumber,
                matches: matches,
            }),
        };
        const response = await fetch('competitions/' + props.competitionId + '/seasons/' + props.seasonId + '/rounds', options);
        if (response.status === 200) {
            formRef.current.reset();
            setError("");
            props.onSubmit();
            setInProgress(false);
        }
        else if (response.status < 500) {

            setError("Competition already exists or invalid name/location field")
        }
        else {
            setError("Unexpected error occured")
        }
    }

    const teamSelector = (index, team, isTeamA) =>
        <Form.Control as="select" size="sm" onChange={(e) => setTeam(e.target.value, index, isTeamA)} value={team?.name}>
            {props.season?.teams?.map(team =>
                <option key={team?.id}>{team?.name}</option>)}
        </Form.Control>

    const createMatch = () => {
        setMatches([
            ...matches,
            { teamA: props.season?.teams[0], teamB: props.season?.teams[0], result: { setResults: [{ teamAPoints: 0, teamBPoints: 0, setNumber: 1 }, { teamAPoints: 0, teamBPoints: 0, setNumber: 2 }, { teamAPoints: 0, teamBPoints: 0, setNumber: 3 }] } }
        ]);
    };

    const setTeam = (name, index, isTeamA) => {
        var team = props.season.teams.find(t => t.name == name)
        if (isTeamA) {
            matches[index].teamA = team
        }
        else {
            matches[index].teamB = team

        }
        setMatches([
            ...matches
        ]);
    };

    const setTeamPoints = (value, index, setIndex, isTeamA) => {
        if (isTeamA) {
            matches[index].result.setResults[setIndex].teamAPoints = value
        }
        else {
            matches[index].result.setResults[setIndex].teamBPoints = value
        }
        setMatches([
            ...matches
        ]);
    };

    return (
        !inProgress ?
            <Button variant="primary" onClick={() => { setInProgress(true); }}>
                {t('Spieltag Hinzufügen')}
            </Button>

            :
            <div className="border rounded px-3 py-2">
                { }
                <h6>{t('Spieltag Hinzufügen')}</h6>
                <br />
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <Form.Label>{t('Nummer')}</Form.Label>
                    <Form.Control type="number" placeholder={t('Enter number of the Round')} onChange={(e) => setRoundNumber(e.target.value)} />
                    <Form.Group as={Row} className="mb-3" controlId="dateInput1">
                       <Form.Label column sm={2}>{t('Spieldatum')}</Form.Label>
                        <Col sm={10}>
                            <Form.Control style={{ width: "33%" }} type="date" value={date} placeholder="Date" onChange={(e) => setDate(e.target.value)} />
                        </Col>

                    </Form.Group>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>{t('TeamA')}</th>
                                <th>{t('TeamB')}</th>
                                <th>{t('Ergebnis')} </th>
                                <th style={{ width: "2%" }}>
                                    <Button onClick={createMatch} className="ml-1">
                                        {t('+')}
                                    </Button ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map((match, index) =>
                                <tr key={index}>
                                    <td>{teamSelector(index, match.teamA, true)} </td>
                                    <td>{teamSelector(index, match.teamB, false)}</td>
                                    <td style={{ width: "35%" }}>
                                        {
                                            match.result?.setResults?.sort((a, b) => a.setNumber - b.setNumber).map((rs, setIndex) =>
                                                <div className="row" key={setIndex}>
                                                    <div className="col"> <Form.Control size="sm" type="text" value={rs.teamAPoints} onChange={(e) => setTeamPoints(e.target.value, index, setIndex, true)} /> </div>
                                                    <div className="col"><Form.Control size="sm" type="text" value={rs.teamBPoints} onChange={(e) => setTeamPoints(e.target.value, index, setIndex, false)} />
                                                    </div>
                                                </div>
                                            )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table >

                    <Button variant="primary" type="submit">
                        {t('Spieltag Speichern')}
                    </Button>
                    {' '}
                    <Button onClick={() => setInProgress(false)} className="ml-1">
                        {t('Close')}
                    </Button >
                </Form>
            </div>
    );
}




